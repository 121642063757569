<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :width="$vuetify.breakpoint.xsOnly ? `96%` : `100%`"
      class="pa-8 h-card-feature rounded-lg mx-auto"
      :elevation="hover ? 12 : 2"
      :class="{ 'on-hover': hover }"
    >
      <div class="features_icon mx-auto my-4">
        <v-img :src="require('@/assets/images/' + icon + '.svg')"></v-img>
      </div>
      <div class="d-flex align-center justify-center pt-6">
        <h4 class="text-center text-h6 grey--text text--darken-2">
          {{ title }}
        </h4>
      </div>
      <v-card-text class="text-center h-feature-text">
        <p class="grey--text text--darken-1 feature-text mb-0 text-body-1">
          {{ text }}
        </p>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'HFeaturesCard',
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
.h-card-feature::v-deep {
  .features_icon {
    width: 120px;
  }

  .feature-text {
    min-height: 86px;
  }
}
</style>
